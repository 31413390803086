




















































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { GetEyeDetail } from "@/request/research";
@Component({})
export default class Name extends Vue {
  private id: any = "";
  private detail: any = {
    left_eye: {
      白睛脉络: {
        上: {
          color: [],
          形状: [],
          走行: [],
        },
        下: {
          color: [],
          形状: [],
          走行: [],
        },
        左: {
          color: [],
          形状: [],
          走行: [],
        },
        右: {
          color: [],
          形状: [],
          走行: [],
        },
        左上: {
          color: [],
          形状: [],
          走行: [],
        },
        左下: {
          color: [],
          形状: [],
          走行: [],
        },
        右上: {
          color: [],
          形状: [],
          走行: [],
        },
        右下: {
          color: [],
          形状: [],
          走行: [],
        },
        特殊眼征: { 特殊眼征: [] },
      },
      黑睛: {
        color: {
          color: "",
        },
        同心圆状白线: {
          全部: [],
          同心圆状白线: "",
          局部: [],
        },
        坑洞: {
          位置: [],
          坑洞: "",
        },
        质地: {
          质地: "",
        },
        辐射状黑线: {
          位置: [],
          辐射状黑线: "",
        },
        黑斑黑点: {
          位置: [],
          黑斑黑点: "",
        },
        黑睛七环: {
          代谢环: "",
          环七: "",
          肠道环: "",
        },
      },
    },
    right_eye: {
      白睛脉络: {
        color: {},
        位置: { 位置: [] },
        形状: { 形状: [] },
        特殊眼征: { 特殊眼征: [] },
        走行: {},
      },
      黑睛: {
        color: {
          color: "",
        },
        同心圆状白线: {
          全部: [],
          同心圆状白线: "",
          局部: [],
        },
        坑洞: {
          位置: [],
          坑洞: "",
        },
        质地: {
          质地: "",
        },
        辐射状黑线: {
          位置: [],
          辐射状黑线: "",
        },
        黑斑黑点: {
          位置: [],
          黑斑黑点: "",
        },
        黑睛七环: {
          代谢环: "",
          环七: "",
          肠道环: "",
        },
      },
    },
    l_eye_image: [],
    r_eye_image: [],
    专家建议: {
      按诊断分: {
        中医疾病: [],
        中医证型: [],
        西医疾病: [],
      },
    },
  };
  private getDetail() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        eye_id: this.id,
      },
    };
    GetEyeDetail(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.detail = data;
      })
      .catch(() => {
        loading.close();
      });
  }
  private mounted() {
    this.id = this.$route.query.id;
    this.getDetail();
  }
}
